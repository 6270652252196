import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { updateState } from '../features/counter/counterSlice';
import SearchDropdown from './navBar/searchDropdown';

const defiant = window.defiant;
const tgg = window.tgg;

export function performDrugSearch(drugTerm, gSnapshot) {
  const xPress = `//*[contains(d, "${drugTerm}")]`;
  let results = defiant.search(gSnapshot, xPress);
  let exactMatch, exactMatchDrug;

  let matchResults = results.filter(result => {
    let matchFound = false;
    if (result.d !== undefined) {
      Object.keys(result.d).forEach(key => {
        if (result.d[key].toLowerCase().includes(drugTerm)) {
          matchFound = true;
        }
        if (result.d[key].toLowerCase() === drugTerm) {
          exactMatch = result;
          exactMatchDrug = key;
        }
      });
    }
    return matchFound;
  });

  let optionCount = 0;
  let flattedResults = [];
  matchResults
    .forEach((result) => {
      Object.keys(result.d).forEach((i) => {
        // if (optionCount >= 40) return;
        const currentDrug = result.d[i];
        if (currentDrug.toLowerCase().split(' ')[0].startsWith(drugTerm.split(' ')[0])) {
          flattedResults.push({ i: result.i, d: currentDrug, k: result.k, t: result.t, s: result.s });
          optionCount++;
        }
      });
    });

  flattedResults.sort((a, b) => {
    const aIndex = a.d.toLowerCase().indexOf(drugTerm);
    const bIndex = b.d.toLowerCase().indexOf(drugTerm);
    if (aIndex === bIndex) {
      return a.d.localeCompare(b.d);
    }
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  // debugger;

  return { flattedResults, exactMatch, exactMatchDrug };
}

const DrugIndexModal = (props) => {
  const { push } = props;
  const [drugSearchVal, setDrugSearchVal] = useState('');
  const dispatch = useDispatch();
  const [gSnapshot, setGSnapshot] = useState(0);
  const [isOpen, setIsOpen] = useState(false)
  const [items, setItems] = useState([]);
  let results = {};

  useEffect(() => {
    defiant.getSnapshot(tgg.guidelines, (gsnapshot) => {
      setGSnapshot(gsnapshot);
    });
  }, []);

  const findPathAndObject = (obj, targetKey, targetValue, path = '') => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key;

        if (key === targetKey && obj[key] === targetValue) {
          return { path: newPath, object: obj[key] };
        } else if (typeof obj[key] === 'object') {
          const result = findPathAndObject(obj[key], targetKey, targetValue, newPath);
          if (result) return result;
        }
      }
    }
    return null;
  }

  const keyupSearchHandler = (event) => {
    const drugTerm = event.target.value.toLowerCase();
    setDrugSearchVal(drugTerm);

    if (!drugTerm || drugTerm.length < 3) {
      setIsOpen(false);
      setItems([]);
      return;
    }

    results = performDrugSearch(drugTerm, gSnapshot);

    setItems(results);
    setIsOpen(true);

    handleSelect(drugTerm);
  };

  const handleSelect = (drugTerm) => {
    let exactMatch, exactMatchDrug, itemId, drugKey;
    if (results && results.exactMatch) {
      exactMatch = results.exactMatch;
      exactMatchDrug = results.exactMatchDrug;
      itemId = exactMatch.i;
      drugKey = exactMatchDrug;
    } else if (drugTerm.i) {
      itemId = drugTerm.i;
      drugKey = drugTerm.d[0];
    } else {
      return;
    }

    const guidelineResult = defiant.search(tgg.guidelines, `//*[i=${itemId}]`)[0];
    const itemPath = findPathAndObject(tgg.guidelines, 'i', guidelineResult.i).path.split(".")[1];
    console.log('itempath', itemPath);

    let guidelineValue = guidelineResult.g;
    if (guidelineValue === undefined) {
      guidelineValue = Object.keys(tgg.guidelines).find(key => tgg.guidelines[key][itemPath] !== undefined);
    }
    const topicSearchResult = defiant.search(tgg.topics, `//*[i=${guidelineResult.t}]/..`)[0];
    const topicValue = Object.keys(topicSearchResult).find(key => tgg.topics[guidelineValue][key].i === guidelineResult.t);
    const pushURL = `/viewTopic?etgAccess=true&guidelinePage=${encodeURIComponent(guidelineValue)}&topicfile=${encodeURIComponent(topicValue)}.ditamap#${itemPath}|${drugKey}`;
    push(pushURL);
  }

  return createPortal(
    <div id="drugIndexModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title" style={{ fontSize: '18px', fontFamily: 'FranklinGothicITCbyBT-Book, Helvetica, sans-serif' }}>Drug index</h4>
          </div>
          <div className="modal-body">
            <div className="input-group search-widget col-xs-12">
              <div className="form-group">
                <form id="searchFormfordrugpopup" name="searchFormfordrugpopup" action="/searchAction" method="GET" onSubmit={(e) => e.preventDefault()} encType="multipart/form-data" acceptCharset="UTF-8">
                  <input
                    type="text"
                    value={drugSearchVal}
                    id="drugnamepopup"
                    className="form-control ui-autocomplete-input"
                    placeholder="Type drug name"
                    autoComplete="off"
                    style={{ fontFamily: 'DINNeuzeitGrotesk-Light, sans-serif' }}
                    onKeyUp={(e) => keyupSearchHandler(e, push, dispatch, updateState)}
                    onChange={(e) => setDrugSearchVal(e.target.value)}
                  />
                </form>

                {isOpen && drugSearchVal.length > 2 && <SearchDropdown items={items} handleSelect={handleSelect} keyword={drugSearchVal} type="drugIndex" />}
              </div>
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>,
    document.getElementById('portal')
  )
}

export default DrugIndexModal;
