import React from "react";

const SearchDropdown = (props) => {
  let { items, keyword, type } = props;
  let isDrugIndex = 'flattedResults' in items;
  if (isDrugIndex) {
    items = items.flattedResults;
  }

  const styles = {
    noResults: {
      padding: '8px 16px',
      color: '#6b7280',
      fontSize: '14px'
    },
    highlight: {
      backgroundColor: '#FFEB3B',
      padding: '0 1px'
    }
  }

  const highlightText = (text, keyword) => {
    if (!keyword.trim()) return text;
    
    const parts = text.split(new RegExp(`(${keyword})`, 'gi'));
    return parts.map((part, index) => 
      part.toLowerCase() === keyword.toLowerCase() ? 
        <span key={index} style={styles.highlight}>{part}</span> : 
        part
    );
  };

  return <div className={type === 'search' ? 'search-dropdown' : 'drug-index-dropdown'}>
    {items.length > 0 ? (
      items.map((item, index) => {
        let item_text = item;
        if (isDrugIndex) {
          item_text = item.d;
        }
        return <div
          key={index}
          className="dropdown-item"
          onClick={() => {
            props.handleSelect(item)
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          {isDrugIndex? highlightText(item_text, keyword) : item_text}
        </div>
      })
    ) : (
      <div style={styles.noResults}>No results found</div>
    )}
  </div>;
};

export default SearchDropdown;