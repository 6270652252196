/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  selectiDB,
  selectVersion,
  updateState,
  UpdateVersionByImport,
  selectVersionByImport,
  selectID 
} from "../../features/counter/counterSlice";
const downloadWorker = new Worker(
  new URL("../../workers/download.js", import.meta.url),
  { type: "module" }
);

const NavBar = React.lazy(() => import("../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../components/topicBanner"));
const Footer = React.lazy(() => import("../../components/footer"));

const Import = (props) => {
  const { pathname, search, push } = props;
  const iDB = useSelector(selectiDB);
  const [idbKeys, setIdbKeys] = useState(0);
  const ccmsversion = useSelector(selectVersion);
  const [version, setVersion] = useState("");
  const [result, setResult] = useState([]);
  const versionByImport = useSelector(selectVersionByImport);
  const dispatch = useDispatch();
  const authID = useSelector(selectID);  

  useEffect(() => {
    const isAuthenticated = authID.u || authID.i;
    if (!isAuthenticated) {
      push('/');
      return;
    }

    console.log("versionByImport in import", versionByImport);
    iDB.keys().then((keys) => {
      setIdbKeys(keys);
      console.log("keys:", keys);
      console.log("ccmsversion", ccmsversion.selected);
    });
  }, []);

  const handleVersionChange = (e) => {
    const newValue = e.target.value;
    iDB.clear().then(() => {
      iDB.keys().then((keys) => {
        setIdbKeys(keys);

        setVersion(newValue);
        dispatch(
          updateState({ version: { ...ccmsversion, selected: newValue } })
        );
        iDB.config({ name: newValue });
        // downloadWorker.postMessage({ command: 'Import'});
        dispatch(UpdateVersionByImport(true));
      });
    });
  };
  const handleFile = (f) => {
    const dateBefore = new Date();
    downloadWorker.postMessage({
      command: "Import",
      buffer: f,
      idbKeys,
      ccmsversion: version,
    });
    downloadWorker.addEventListener("message", (event) => {
      console.log("Message from worker:", event.data);
    });

    // JSZip.loadAsync(f)
    //   .then((zip) => {
    //     const dateAfter = new Date();
    //     const fileContent = [];
    //     zip.forEach((relativePath, zipEntry) => {
    //       fileContent.push(zipEntry.name);
    //     });
    //     setResult((prevResult) => [
    //       ...prevResult,
    //       {
    //         title: `${f.name} (loaded in ${dateAfter - dateBefore}ms)`,
    //         content: fileContent,
    //       },
    //     ]);
    //   })
    //   .catch((e) => {
    //     setResult((prevResult) => [
    //       ...prevResult,
    //       {
    //         title: `Error reading ${f.name}`,
    //         content: [e.message],
    //       },
    //     ]);
    //   });
  };
  const handleFileChange = (evt) => {
    setResult([]);
    const files = evt.target.files;
    for (let i = 0; i < files.length; i++) {
      handleFile(files[i]);
    }
  };

  useEffect(() => {
    iDB.keys().then((keys) => {
      setIdbKeys(keys);
      // console.log("keys:",keys);
      console.log(ccmsversion);
    });
  }, []);
  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} />
          <div className="import-card-container" style={{ height: "50vh" }}>
            <div className="import-card">
              <h3 className="text-center" style={{ textAlign: "center" }}>
                Select Content Slot
              </h3>
              <div className="form-group" style={{ "marginBottom:": "15px" }}>
                <label htmlFor="options">Change slot:</label>
                <select
                  className="import-form-control"
                  id="options"
                  value={version}
                  onChange={(e) => handleVersionChange(e)}
                >
                  <option value="" disabled>
                    Select a version
                  </option>
                  {ccmsversion.items.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {versionByImport && (
                  <p style={{ color: "green" }}>
                    Content has been successfully updated to {version}{" "}
                  </p>
                )}
              </div>
            </div>
            {/* <div className='col-md-4 bg-info'>
              <h3 className='text-center'>3. Upload a local zip file</h3>
              <div>
                <input
                  type='file'
                  id='file'
                  name='file'
                  multiple
                  onChange={handleFileChange}
                  className='form-control'
                />
                {result.map((file, index) => (
                  <div key={index}>
                    <h4>{file.title}</h4>
                    <ul>
                      {file.content.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        <Footer />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(Import);
